(function() {
  'use strict';

  new IntersectionObserver(([entry]) => {
    if (window.visualViewport.pageTop >= entry.boundingClientRect.top) {
      document.getElementById('FixedHeader').classList.toggle('hidden');
    }
  }, { rootMargin: '-139px 0% -60%', threshold: 0.01 }).observe(document.querySelector('.navigation-observer'));

  document.getElementById('MobileHeader').addEventListener('click', e => {
    if (e.target.localName === 'a') {
      document.getElementById('MobileMenuToggle').checked = false;
    }
  });
})();